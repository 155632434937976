import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../../components/header_resources"

export default function SelectingAFundManager() {
  const heroTitle = "Selecting A Fund Manager"

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="w-100 mb-5 sm:mmb-5">
        <Link class="back--home" to="/resources/blog">
          <i class="fas fa-chevron-left"></i> <span>Back to Blog</span>
        </Link>
      </div>

      <div class="innerpage--content innerpage--content-sm blog--details m-0 p-0">
        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p>
          What is the first thing you do when you think about investing in a mutual fund? If you
          are like our parents, you would call up one of the bank related mutual fund providers
          such as HDFC, ICICI, Axis, SBI or Kotak Mutual Fund or go by the advice of your
          bank relationship manager to invest in their recommended (or at times mis-sold)
          product without caring or understanding what is it that you are investing in.
        </p>

        <p>
          Most investors do not realize that it is not the bank or the relationship manager who
          manages the underlying funds but a fund manager (a person) and his / her team of
          investment analysts that effectively acts as a custodian of your money and invests it
          for you in buying shares, bonds etc. While we invest in a mutual fund that we can
          recall through excessive branding, we need to remember that there is an actual
          human being in those funds that is effectively allocating your money on your behalf.
        </p>

        <p>
          When an investor considers investing in a mutual fund, he / she needs to analyze a
          range of issues, some quantitative and some qualitative. The analysis should extend
          a bit further than just simply looking at the brand, marketing and advertising of the
          fund or looking at the past returns of the mutual fund.
        </p>

        <p>
          In this blog post we highlight some pointers that we believe could help investors to
          make an informed decision, if not the best decision, when selecting a mutual fund.
        </p>

        <div class="row row-full justify-content-center mb-5 d-none">
          <div class="col-12 col-md-12">
            <figure class="w-100">
              <img
                src="static/images/blog/blog_details.jpg"
                class="img-fluid o-media--image"
                alt="Article Info"
              />
              <figcaption class="figcaption">Top Tips for Picking a Winning Mutual Fund</figcaption>
            </figure>
          </div>
        </div>

        <h2 class="h3 h3-sm">Historical performance</h2>

        <p>
          The most obvious reason to hire a fund manager is attractive investment returns.
          While performance monitoring is important, it should only be done periodically and
          must be consistent with your original investment goals of investing in the fund
          managed by that fund manager. There are various styles and strategies of investing
          and no style or strategy can consistently perform across all market and business
          cycles. Periods of outperformance generally follow periods of underperformance.
          When a fund manager underperforms, it must be examined in a proper context to
          determine whether the problems are addressable and if there is a chance of
          acceptable performance to resume.
        </p>

        <p>
          Investors should be disciplined and not be influenced by recent performance, which
          could lead to making bad choices. A majority of long term (10+ years) good performing equity fund managers
          regularly have short periods (2-4 years) of underperformance, and sometimes quite
          significantly. Short term underperformance is generally a trait, not a bug, of top
          performing fund managers.
        </p>

        <h2 class="h3 h3-sm">
          Not following the process / drifting away from the investment style
        </h2>

        <p>
          This is a hard one to ascertain as for this to happen an investor should educate
          themselves on the manager’s investment philosophy and processes before even
          investing in the product. Ideally this would encompass rules relating to the sector,
          industry, market cap and style. By understanding these variables, the investor can
          judge if the fund manager’s strategy and risk profile is understandable and a good fit
          for the investor’s investment objectives and the portfolio.
        </p>

        <p>
          While there are always exceptions, generally a fund manager drifting too much away
          from his/her defined process is likely to result in suboptimal outcomes for the
          portfolio over the medium to long term.
        </p>

        <p>
          There is no one right or correct investing style that is required to be pursued. In fact,
          it helps to allocate funds to different investing styles as part of portfolio construction
          to maximise benefits of diversity. However, the one that ultimately works best for you
          depends in large part on your risk tolerance, time horizon for investing and
          investment goals. As you grow older your preferred investment style is likely to shift
          as your own investment objectives evolve.
        </p>

        <p>
          But these changes should be made by you, not your manager. If a manager starts
          investing in a way inconsistent with the style you understood when first investing for
          example a momentum fund investing based on value investing principles, it is time to
          look for a new manager who meets your expectations.
        </p>

        <h2 class="h3 h3-sm">Too much money under management</h2>

        <p>
          Any active equity strategy will have its own limit - in particular, strategies those
          oriented towards mid and smallcap companies. As assets under management grow,
          the manager’s ability to outperform slowly erodes. ‘Fund capacity’ is the maximum
          amount of money a fund manager can deploy in a strategy and can still expect to
          achieve decent performance targets.
        </p>

        <p>Therefore, when a fund reaches or exceeds its fund capacity it is a massive red flag.
        It generally suggests to us that the mutual fund is not acting in their clients best
        interest but rather trying to maximise their profitability by collecting fees on higher
        funds under management. This for instance is a clear prompt for investors to exit and
        seek a new fund as the incentive for the fund manager has changed and this is likely
        to result in poor future performance.
        </p>
        
        <p>Some funds and fund managers do act in their investor’s best interest and
        temporarily shut down a scheme for further lumpum or SIP investments when they
        believe the ‘fund capacity’ has been reached.
        </p>

        <h2 class="h3 h3-sm">Alignment of incentives</h2>

        <p>
          Incentives drive behaviour. A fund manager should strive to align the interest of its
          investors with his / her own. If they don’t, the interests of the firm and its staff can be
          prioritised over its investors.
        </p>

        <p>
          A number of fund managers - for instance, the investment team of Parag Parikh
          Mutual Fund invest in their own funds and take pride in having their skin in the game.
        </p>

        <h2 class="h3 h3-sm">Stable or unstable Investment Teams</h2>
        <p>
          Over the years, we have seen funds with a track record of consistently good performance
          languish after a fund manager leaves the firm. Irrespective of whether they are retiring
          or jumping ship or starting their own PMS or AIF, a star fund manager’s exit poses
          important questions to investors such as how much of their performance was due to their
          unique skill and how much is due to the firm investment philosophy and process and whether
          the second line of investment team and fund managers are able to fill-in the shoes?
        </p>

        <p>
          To protect against this risk, we strive to invest in products that have a good bench
          strength and have a team of experienced fund managers. And sitting above the team is a
          systemised and established investment process, including robust company research.
        </p>

        <h2 class="h3 h3-sm">Downside capture ratio</h2>
        <p style={{ textAlign: "center" }}>
          Downside Capture Ratio = Scheme Returns During Down Market / Index Returns
          During Down Market * 100
        </p>

        <p>
          An investor also needs to look at the “downside capture ratio” of a fund manager and his / her
          funds. This helps ascertain overall performance of the fund manager in down markets. A
          fund or fund manager that loses less when the markets fall typically bounces back the
          fastest. This ensures that the investors have a relatively smoother investment experience.
          A fund manager having a downside capture ratio of less than 100 is said to have
          outperformed the index during the down market by falling less than the index. Fund
          managers who over various down markets exhibit a downside capture ratio of 100 should be
          preferred.
        </p>

        <p>
          It is time to review the performance and strategy of a fund manager who across various
          down market cycles has a downside capture ratio of over 100 compared to their benchmark
          index.
        </p>

        <h2 class="h3 h3-sm">Selecting Funds</h2>
        <p>
          A lot of thought and consideration goes into taking a decision regarding selecting your
          fund manager. Generally, even the information required is not readily available. The other
          worry is also that once the manager is changed, the new fund manager might underperform as
          well. There is no way of knowing the future in advance.
        </p>

        <p>
          Despite having a checklist in place in terms of manager selection, it is still difficult to
          know ex ante if your selected manager will do well. However, as investors and advisors we are best served by having a process or framework to
          help make an educated decision regarding the fund manager.
        </p>
      </div>
    </HeaderResources>
  )
}
